<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import type { ContentTermExam } from '~/models/Content/ContentTermExam'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { findGradesByGradeType } from '~/utils/gradeSorter'
import { setTitle } from '~/utils/dom'
import { useAuthStore } from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import useSearchColors from '~/composables/useSearchColors'
import { useAppHeaderPosition } from '~/composables/useAppHeaderPosition'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import ExamsSearchResults from '~/components/products/exams/ExamsSearchResults.vue'
import ExamsSearchFilters from '~/components/products/exams/ExamsSearchFilters.vue'

const supportedGradeTypes = [GradeType.LowerSecondary, GradeType.UpperSecondary]

const { product } = defineProps<{ product: ContentProduct }>()

useAppColor({ themeColor: ColorName.Blue60, bgColor: ColorName.Gray5 })
useAppHeaderPosition('relative')

const { t } = useI18n()
const { colorVariables } = useSearchColors()
const { findContents } = useContentApi()
const { isTeacher } = storeToRefs(useAuthStore())

const { selectedGradeType } = storeToRefs(useAuthStore())

const selectedGrades = ref<GradeCode[]>([])

const availableGrades = computed(() => {
  const gradeType = selectedGradeType.value && supportedGradeTypes.includes(selectedGradeType.value)
    ? selectedGradeType.value
    : supportedGradeTypes[0]
  return findGradesByGradeType(gradeType)
})

const filterExams = (exams: (ContentTermExam | ContentProductHeader)[]) => {
  const { TermExam, ProductHeader } = ContentType
  const newTermExams = exams.find(exam => exam.contentTypeIdentifier === TermExam) as ContentTermExam
  const oldTermExams = exams.filter(exam => exam.contentTypeIdentifier === ProductHeader) as ContentProductHeader[]
  return { newTermExams, oldTermExams }
}

const { data, isError, isLoading } = useQuery({
  queryKey: computed(() => ['product_exams', product.locationId]),
  queryFn: () => findContents<ContentTermExam | ContentProductHeader>({
    parentLocationIdCriterion: [product.locationId],
    contentTypeCriterion: isTeacher.value
      ? [ContentType.TermExam, ContentType.ProductHeader]
      : [ContentType.ProductHeader],
    sortField: product.sortField,
    sortOrder: product.sortOrder,
  }),
  select: filterExams,
  enabled: computed(() => !!product.pathString),
  staleTime: Infinity,
})

const newTermExams = computed(() => data.value?.newTermExams ?? {} as ContentTermExam)
const oldTermExams = computed(() => data.value?.oldTermExams ?? [])

watch(() => product, () => setTitle(product.title), { immediate: true })
watch(selectedGradeType, () => selectedGrades.value = [...availableGrades.value], { immediate: true })
</script>
<template>
  <div
    :style="[colorVariables, '--ks-roundness: .3']"
    class="mx-auto my-12 grid max-w-screen-au grid-cols-1 grid-rows-[auto,auto,auto,auto,auto] gap-x-8 gap-y-4 px-4 text-blue-60 grid-areas-exams-view-mobile sm:px-8 md:grid-cols-4 md:gap-x-12 md:grid-areas-exams-view-tablet"
  >
    <h1
      class="text-3xl font-extrabold grid-in-heading"
      v-text="product.title"
    />
    <ExamsSearchFilters
      v-model="selectedGrades"
      :options="availableGrades"
    />
    <ExamsSearchResults
      :new-term-exams
      :old-term-exams
      :selected-grades
      :is-loading
    />
    <p
      v-if="isError"
      v-text="t('assign.error')"
    />
  </div>
</template>
