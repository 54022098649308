<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import type { ContentTermExam } from '~/models/Content/ContentTermExam'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import ExamsShared from '~/components/products/exams/ExamsShared.vue'
import ExamsOld from '~/components/products/exams/ExamsOld.vue'
import ExamsNew from '~/components/products/exams/ExamsNew.vue'

const {
  oldTermExams,
  newTermExams,
  selectedGrades,
  isLoading
} = defineProps<{
  oldTermExams: ContentProductHeader[]
  newTermExams: ContentTermExam
  selectedGrades: GradeCode[]
  isLoading: boolean
}>()

const { userOrganizations, isTeacher } = storeToRefs(useAuthStore())

const acoOrgNumbers = [
  'NO456326497', // Aschehoug Grunnskole 1 (Nye Lokus testskole)
  'NO456326498', // Aschehoug Grunnskole 2 (Nye Lokus testskole)
  'NO910292005', // Aschehoug grunnskole 3
  'NO971660082', // Aschehoug grunnskole 3
  'NO971744987', // Aschehoug grunnskole 4
]

const showNewExams = computed(() => {
  return newTermExams.underConstruction
    ? userOrganizations.value.some(org => acoOrgNumbers.includes(org.number))
    : true
})
</script>

<template>
  <div class="space-y-10 grid-in-results">
    <ExamsShared
      v-if="showNewExams"
      :selected-grades="selectedGrades"
    />
    <ExamsNew
      v-if="isTeacher && showNewExams"
      :exams="newTermExams"
      :selected-grades="selectedGrades"
      :is-loading
    />
    <ExamsOld
      :exams="oldTermExams"
      :selected-grades="selectedGrades"
      :is-loading
    />
  </div>
</template>
